<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">Inventory Transfer Detail</h1>
        </div>
        <b-tabs class="mt-3">
          <b-tab title="Information">
            <div class="title-tabs">General Information</div>
            <div class="p-3 bg-white">
              <b-row>
                <b-col cols="12" sm="6">
                  <InputText
                    textFloat="Transaction Name"
                    placeholder="Transaction Name"
                    type="text"
                    isRequired
                    name="transaction_name"
                    :v="$v.form.name"
                    :isValidate="$v.form.name.$error"
                    v-model="form.name"
                /></b-col>
                <b-col cols="12" sm="6">
                  <InputText
                    textFloat="Transaction Code"
                    placeholder="Transaction Code"
                    type="text"
                    isRequired
                    name="transaction_code"
                    :v="$v.form.code"
                    :isValidate="$v.form.code.$error"
                    v-model="form.code"
                /></b-col>
                <b-col cols="12">
                  <InputTextArea
                    textFloat="Description"
                    placeholder="Description"
                    :value="form.description"
                    isRequired
                    :v="$v.form.description"
                    :isValidate="$v.form.description.$error"
                    v-model="form.description"
                    rows="5"
                  />
                </b-col>
                <b-col cols="12" sm="6">
                  <InputText
                    textFloat="Prefix"
                    placeholder="Prefix"
                    type="text"
                    isRequired
                    name="prefix"
                    :v="$v.form.document_no_prefix"
                    :isValidate="$v.form.document_no_prefix.$error"
                    v-model="form.document_no_prefix"
                /></b-col>
                <b-col cols="6">
                  <section>
                    <div class="title">Example :</div>
                    <p>
                      {MM} : 2 Digit of Month of Transaction eg. 01,02,12 <br />
                      {YY} : 2 Digit of Year of Transaction eg. Year 2020 : 20
                      <br />
                      {Branch Code} : Branch Code
                      <br />
                      {DD} : Day 2 Digit <br />
                      {Running_No} : Running Number
                    </p>
                  </section>
                </b-col>
                <b-col cols="6">
                  <label class="main-label"
                    >No. Length (Reset every month)</label
                  >
                  <br />
                  <div
                    class="form-check form-check-inline"
                    v-for="i in 6"
                    :key="i"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      :value="i + 5 - 1"
                      v-model="form.document_no_length"
                    />
                    <label class="form-check-label" for="inlineRadio1">
                      {{ i + 5 - 1 }}
                    </label>
                  </div>
                </b-col>
              </b-row>
            </div>
            <div class="title-tabs">Source Branch</div>
            <div class="p-3 bg-white">
              <b-row class="align-items-center">
                <b-col cols="4" lg="3">
                  <InputText
                    textFloat=""
                    placeholder="Search Branch name."
                    type="text"
                    class="mb-0"
                    name="search-source"
                    v-model="source.filter.search"
                  />
                </b-col>
                <b-col cols="4" lg="4">
                  <b-form-radio-group
                    v-model="form.source_branch_type"
                    :options="transferOptions"
                    value-field="item"
                    text-field="name"
                    :stacked="false"
                    @change="(val) => checkType(1, val)"
                    disabled-field="notEnabled"
                  ></b-form-radio-group
                ></b-col>
                <b-col
                  cols="4"
                  lg="4"
                  class="ml-auto text-right"
                  v-if="form.source_branch_type == 1"
                >
                  <b-button variant="outline-primary" @click="addBranch(1)">
                    <font-awesome-icon
                      icon="plus"
                      class="text-black d-sm-none"
                    />
                    <span class="d-none d-sm-block">
                      <font-awesome-icon icon="plus-square" class="pointer" />
                      Add Branch
                    </span>
                  </b-button>
                </b-col>
              </b-row>
              <b-table
                striped
                hover
                responsive
                :items="form.source_branches"
                :fields="sourceField"
                :busy="source.isBusy"
                :per-page="source.filter.take"
                :current-page="source.filter.page"
                show-empty
                empty-text="No matching records found"
                class="mt-2"
              >
                <template v-slot:cell(action)="{ item }">
                  <font-awesome-icon
                    icon="trash-alt"
                    @click="removeBranch(1, item.branch_id)"
                    class="ft-14 ml-2 cursor-pointer"
                  />
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template></b-table
              >
              <Pagination
                @handleChangeTake="onPerPageChange"
                @pagination="pagination"
                :filter="source.filter"
                :rows="source.rows"
              />
              <div class="text-error" v-if="$v.form.source_branches.$error">
                Please Select Branch At Least 1.
              </div>
            </div>
            <div class="title-tabs">Destination Branch</div>
            <div class="p-3 bg-white">
              <b-row class="align-items-center">
                <b-col cols="4" lg="3">
                  <InputText
                    textFloat=""
                    placeholder="Search Branch name."
                    type="text"
                    class="mb-0"
                    name="search-source"
                    v-model="destination.filter.search"
                  />
                </b-col>
                <b-col cols="4" lg="4">
                  <b-form-radio-group
                    v-model="form.destination_branch_type"
                    :options="transferOptions"
                    value-field="item"
                    text-field="name"
                    :stacked="false"
                    disabled-field="notEnabled"
                    @change="(val) => checkType(2, val)"
                  ></b-form-radio-group
                ></b-col>
                <b-col
                  cols="4"
                  lg="4"
                  class="ml-auto text-right"
                  v-if="form.destination_branch_type == 1"
                >
                  <b-button variant="outline-primary" @click="addBranch(2)">
                    <font-awesome-icon
                      icon="plus"
                      class="text-black d-sm-none"
                    />
                    <span class="d-none d-sm-block">
                      <font-awesome-icon icon="plus-square" class="pointer" />
                      Add Branch
                    </span>
                  </b-button>
                </b-col>
              </b-row>
              <b-table
                striped
                hover
                responsive
                :items="form.destination_branches"
                :fields="sourceField"
                :busy="destination.isBusy"
                :per-page="destination.filter.take"
                :current-page="destination.filter.page"
                show-empty
                empty-text="No matching records found"
                class="mt-2"
              >
                <template v-slot:cell(action)="{ item }">
                  <font-awesome-icon
                    icon="trash-alt"
                    @click="removeBranch(2, item.branch_id)"
                    class="ft-14 ml-2 cursor-pointer"
                  />
                </template>
                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template></b-table
              >

              <Pagination
                @handleChangeTake="onPerPageChange"
                @pagination="pagination"
                :filter="destination.filter"
                :rows="destination.rows"
              />
              <div
                class="text-error"
                v-if="$v.form.destination_branches.$error"
              >
                Please Select Branch At Least 1.
              </div>
            </div>
            <div class="no-gutters bg-white mt-3 py-2 px-3">
              <b-form-checkbox
                switch
                v-model="form.status"
                class="radio-active"
                size="lg"
                :value="1"
                :unchecked-value="0"
              >
                <span class="ml-2 main-label">{{
                  form.status ? "Active" : "Inactive"
                }}</span>
              </b-form-checkbox>
            </div>
          </b-tab>
          <b-tab title="History" v-if="parseInt($route.params.id) !== 0">
          </b-tab>
        </b-tabs>
      </b-form>

      <!-- <SidebarFilterCustomerCoupon
        ref="sidebarFilterUsedCoupon"
        :filter="filterCustomer"
        @searchFilter="searchFilter"
      /> -->
    </div>
    <ModalBranch
      ref="modalBranch"
      title="source branch"
      @selectBranch="selectBranch"
      :selectedList="selectedList"
      :disabledList="disabledList"
    />
    <FooterAction routePath="/setting/inventory-transfer" @submit="submit(0)" />
  </div>
</template>

<script>
import { required, helpers, requiredIf } from "vuelidate/lib/validators";
import ModalBranch from "./component/modalBranch";
export default {
  components: {
    ModalBranch,
  },
  validations: {
    form: {
      name: { required },
      code: { required },
      description: { required },
      document_no_prefix: { required },
      source_branches: {
        required: requiredIf(function (item) {
          return item.source_branch_type == 1;
        }),
      },
      destination_branches: {
        required: requiredIf(function (item) {
          return item.destination_branch_type == 1;
        }),
      },
    },
  },
  data() {
    return {
      isLoading: false,

      // sourceItems: [],
      source: {
        items: [],
        filter: {
          take: 5,
          page: 1,
          search: "",
        },
        rows: 0,
        isBusy: false,
      },
      destination: {
        items: [],
        filter: {
          take: 5,
          page: 1,
          search: "",
        },
        rows: 0,
        isBusy: false,
      },
      sourceField: [
        { key: "plant_id", label: "Plant ID." },
        { key: "branch_name", label: "Branch Name" },
        { key: "shot_name", label: "Branch Short Name" },
        { key: "branch_group_name", label: "Branch Group" },
        { key: "action", label: "Action" },
      ],
      transferOptions: [
        { item: 0, name: "Login Branch" },
        { item: 1, name: "Select Branch" },
      ],
      form: {
        name: "",
        code: "",
        description: "",
        document_no_prefix: "",
        document_no_length: 10,
        source_branch_type: 1, // 0 login-branch,1 select branch
        destination_branch_type: 1, // 0 login-branch,1 select branch
        status: 1,
        source_branches: [
          // {
          //   plant_id: "",
          //   branch_id: 1449,
          //   branch_name: "testshopdosetech",
          //   shot_name: "testshopdosetech",
          //   branch_group_name: "Other",
          //   branch_type: 1,
          // },
        ],
        destination_branches: [
          // {
          //   plant_id: "T7495131313266592161",
          //   branch_id: 1448,
          //   branch_name: "KCINTERFOODS",
          //   shot_name: "KCINTERFOODS",
          //   branch_group_name: "Other",
          //   branch_type: 2,
          // },
        ],
      },

      selectedList: [],
      branchMode: 0,
      disabledList: [],
    };
  },
  created() {
    if (this.$route.params.id != 0) this.getDetail();
  },
  methods: {
    async submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      this.$bus.$emit("showLoading");
      var res = null;
      if (this.$route.params.id != 0) {
        res = await this.axios.put(
          `/InventoryTransfer/Update/${this.$route.params.id}`,
          this.form
        );
      } else {
        res = await this.axios.post("/InventoryTransfer/Create", this.form);
      }
      this.$bus.$emit("hideLoading");
      if (res.data.result)
        this.successAlert(res.data.message).then(() =>
          this.$router.push("/setting/inventory-transfer")
        );
      else this.errorAlert(res.data.message);
    },
    async getDetail() {
      const res = await this.axios(
        `/InventoryTransfer/${this.$route.params.id}`
      );
      this.form = res.data.detail;
      this.form.destination_branches = [];
    },
    onPerPageChange() {},

    async addBranch(type) {
      this.branchMode = type;
      console.log(this.form);
      this.selectedList =
        type == 2 ? this.form.destination_branches : this.form.source_branches;
      this.disabledList =
        type == 2 ? this.form.source_branches : this.form.destination_branches;
      await this.$nextTick();
      this.$refs.modalBranch.show();
      // this.getBranchList();
    },
    selectBranch(val) {
      if (this.branchMode == 2) {
        this.form.destination_branches = val.map((el) => {
          return { ...el, branch_type: 2 };
        });
        this.destination.rows = val.length;
      }
      if (this.branchMode == 1) {
        this.form.source_branches = val.map((el) => {
          return { ...el, branch_type: 1 };
        });
        this.source.rows = val.length;
      }
    },
    pagination() {},
    checkType(type, val) {
      if (type == 2 && val == 0) this.form.source_branch_type = 1;
      if (type == 1 && val == 0) this.form.destination_branch_type = 1;
    },
    removeBranch(type, id) {
      if (type == 2) {
        let index = this.form.destination_branches.findIndex(
          (el) => el.branch_id == id
        );
        this.form.destination_branches.splice(index, 1);
        // this.form.destination_branches = val;
        this.destination.rows = this.form.destination_branches.length;
      }
      if (type == 1) {
        let index = this.form.source_branches.findIndex(
          (el) => el.branch_id == id
        );
        this.form.source_branches.splice(index, 1);
        this.source.rows = this.form.source_branches.length;
      }
    },
  },
};
</script>

<style></style>
