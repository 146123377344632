<template>
  <b-modal
    v-model="isShow"
    no-close-on-backdrop
    centered
    :title="title"
    size="lg"
    @ok="submit"
  >
    <b-table
      striped
      hover
      responsive
      :items="branchList"
      :fields="branchField"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
      class="mt-2"
    >
      <template v-slot:cell(action)="{ item }">
        <b-form-checkbox
          v-model="selected"
          :value="item"
          :disabled="
            disabledList.findIndex((el) => el.branch_id == item.branch_id) != -1
          "
        >
        </b-form-checkbox>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <Pagination
      @handleChangeTake="onPerPageChange"
      @pagination="pagination"
      :filter="branch"
      :rows="rows"
    />
  </b-modal>
</template>

<script>
export default {
  props: {
    title: {
      required: false,
    },
    selectedList: {
      required: false,
    },
    disabledList: {
      required: false,
    },
  },
  data() {
    return {
      isShow: false,
      branchField: [
        { key: "action", label: "Action" },
        { key: "plant_id", label: "Plant ID." },
        { key: "branch_name", label: "Branch Name" },
        { key: "shot_name", label: "Branch Short Name" },
        { key: "branch_group_name", label: "Branch Group" },
      ],
      branch: {
        search: "",
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      selected: [],
      branchList: [],
    };
  },
  methods: {
    async show() {
      this.selected = [...this.selectedList];

      await this.getBranchList();
      this.isShow = true;
    },
    hide() {},
    async getBranchList() {
      this.isBusy = true;
      const res = await this.axios.post(
        "/InventoryTransfer/GetBranchList",
        this.branch
      );
      this.rows = res.data.detail.total_count;
      this.branchList = res.data.detail.branches;
      this.isBusy = false;
    },
    onPerPageChange(val) {
      this.branch.page = 1;
      this.branch.take = val;
      this.getBranchList();
    },
    pagination(val) {
      this.branch.page = val;

      this.getBranchList();
    },
    submit() {
      console.log(this.selected);
      this.$emit("selectBranch", this.selected);
    },
  },
};
</script>

<style></style>
